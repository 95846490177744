import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "ムチでメモへようこそ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#%E3%83%A0%E3%83%81%E3%81%A7%E3%83%A1%E3%83%A2%E3%81%B8%E3%82%88%E3%81%86%E3%81%93%E3%81%9D",
        "aria-label": "ムチでメモへようこそ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ムチでメモへようこそ`}</h1>
    <p>{`このブログはカナダ、バンクーバーで暮らすムチが似合うとか壇蜜に似てるとか言われる ジーナ(`}<a parentName="p" {...{
        "href": "https://twitter.com/muchimemo",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`@muchimemo`}</a>{`) が、日々思ったことをただただ垂れ流しているブログです。`}</p>
    <p><a parentName="p" {...{
        "href": "/category/mm/"
      }}>{`M/Mロマンス小説`}</a>{`を読み始めた時に、この面白さと熱い思いを誰かと共有したくて始めたブログですが、それ以外にも読んだマンガの感想や、海外移住で感じたこと、パートナーとの生活で気づいたことなど、とりとめなく更新していくつもりです。`}</p>
    <h2 {...{
      "id": "好きなもの",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E5%A5%BD%E3%81%8D%E3%81%AA%E3%82%82%E3%81%AE",
        "aria-label": "好きなもの permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`好きなもの`}</h2>
    <h3 {...{
      "id": "マンガ家",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%9E%E3%83%B3%E3%82%AC%E5%AE%B6",
        "aria-label": "マンガ家 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`マンガ家`}</h3>
    <p>{`よしながふみ / 多田由美 / 中村明日美子 / オノ･ナツメ（basso） / ZAKK（石江八） / 座裏屋蘭丸 / 紗久楽さわ / 波津彬子 / 今市子 / えすとえむ / ヨネダコウ / ヤマシタトモコ / 惣領冬実 / 森薫 / 入江亜季 / 船戸明里 / 灰原薬 / 幸村誠 / 羽海野チカ`}</p>
    <h3 {...{
      "id": "mmロマンス小説作家",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#mm%E3%83%AD%E3%83%9E%E3%83%B3%E3%82%B9%E5%B0%8F%E8%AA%AC%E4%BD%9C%E5%AE%B6",
        "aria-label": "mmロマンス小説作家 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`M/Mロマンス小説作家`}</h3>
    <p>{`Josh Lanyon / Alexis Hall / AJ Rose  `}</p>
    <h3 {...{
      "id": "オーディオブックナレーター",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%AA%E3%83%BC%E3%83%87%E3%82%A3%E3%82%AA%E3%83%96%E3%83%83%E3%82%AF%E3%83%8A%E3%83%AC%E3%83%BC%E3%82%BF%E3%83%BC",
        "aria-label": "オーディオブックナレーター permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`オーディオブックナレーター`}</h3>
    <p>{`Kale Williams / Matthew Shaw / Alexander Doddy / Joel Leslie / Darcy Stark`}</p>
    <h3 {...{
      "id": "スタンドアップコメディアン",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%B9%E3%82%BF%E3%83%B3%E3%83%89%E3%82%A2%E3%83%83%E3%83%97%E3%82%B3%E3%83%A1%E3%83%87%E3%82%A3%E3%82%A2%E3%83%B3",
        "aria-label": "スタンドアップコメディアン permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`スタンドアップコメディアン`}</h3>
    <p>{`John Mulaney / Demetri Martin / Sarah Silverman / Trevor Noah / Ali Wong`}</p>
    <h3 {...{
      "id": "ドラマ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%89%E3%83%A9%E3%83%9E",
        "aria-label": "ドラマ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ドラマ`}</h3>
    <p>{`Sherlock / The Last Kingdom / Stranger Things / London Spy`}</p>
    <h2 {...{
      "id": "ちょっとだけ自己紹介",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%81%A1%E3%82%87%E3%81%A3%E3%81%A8%E3%81%A0%E3%81%91%E8%87%AA%E5%B7%B1%E7%B4%B9%E4%BB%8B",
        "aria-label": "ちょっとだけ自己紹介 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ちょっとだけ自己紹介`}</h2>
    <p>{`18歳まで「陸の孤島」と呼ばれるような田舎で育つ。`}<br parentName="p"></br>{`
`}{`19～26歳まで、何度かの転職を繰り返しながら広告業界/IT業界でデザイナーとして働く。同時に何度かの恋愛と失恋を繰り返して男性不振に陥る。`}<br parentName="p"></br>{`
`}{`27歳、思い立って仕事を辞め、アパートを解約してフィリピンに語学留学。`}<br parentName="p"></br>{`
`}{`28歳、カナダのバンクーバーで1年間カレッジに通いつつ生活してみたら、バンクーバーが好きになりすぎて移住することを決意。`}<br parentName="p"></br>{`
`}{`29歳、オンラインデーティングで知り合ったパートナーと付き合い始める。`}<br parentName="p"></br>{`
`}{`32歳、カナダの永住権がおりるのを待ちつつ、M/Mロマンス小説に手を出したら抜け出せなくなるほどハマってブログを始める。`}<br parentName="p"></br>{`
`}{`33歳、カナダの永住権獲得。`}</p>
    <h2 {...{
      "id": "広告に関して",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E5%BA%83%E5%91%8A%E3%81%AB%E9%96%A2%E3%81%97%E3%81%A6",
        "aria-label": "広告に関して permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`広告に関して`}</h2>
    <p>{`「ムチでメモ」は運営維持のため、広告として「Amazonアソシエイト」を含む第三者配信事業者を利用しています。ご了承ください。`}</p>
    <h2 {...{
      "id": "免責事項",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E5%85%8D%E8%B2%AC%E4%BA%8B%E9%A0%85",
        "aria-label": "免責事項 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`免責事項`}</h2>
    <p>{`「ムチでメモ」に掲載されている情報の正確さについて可能な限り努力をしていますが、その正確性や適切性に問題がある場合、告知無しに情報を変更･削除する事があります。`}<br parentName="p"></br>{`
`}{`当ブログの情報を用いて行う一切の行為、被った損害･損失に対しては、一切の責任を負いかねます。ご了承ください。`}</p>
    <h2 {...{
      "id": "お問い合わせ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%81%8A%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B",
        "aria-label": "お問い合わせ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`お問い合わせ`}</h2>
    <ul>
      <li parentName="ul">{`匿名でのお問い合わせは`}<a parentName="li" {...{
          "href": "https://marshmallow-qa.com/muchimemo",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`マシュマロ`}</a>{`で`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://twitter.com/muchimemo",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Twitter(@muchimemo)ダイレクトメッセージ`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/contact/"
        }}>{`お問い合わせフォーム`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      